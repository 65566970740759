
import LazyHydrate from 'vue-lazy-hydration';
import Brand from '@/components/Home/Brand'
import Feature from '@/components/Home/Feature'
import Banner from '@/components/Home/Banner'
import {
  ACTION_GET_BANNER_CUSTOM,
  ACTION_GET_FAQ,
  ACTION_GET_FIGURES,
  ACTION_GET_MANUFACTURERS, ACTION_GET_NEWS, ACTION_GET_PARTNER, ACTION_SET_TOKEN_NOTIFY
} from "@/utils/store.action"
import config from '@/utils/config'
import DialogValuation from '@/components/Elements/DialogValuation'
import RudderStackHandler from "@/mixins/RudderStackHandler";
import {TOKEN_KEY} from "@/utils/constants";
import {getVerticalScrollPercentage} from "@/utils";
import CleverTapHandler from "@/mixins/CleverTapHandler";
import FeatureCard from "@/components/Home/FeatureCard.vue";
import ServiceCard from "@/components/Home/ServiceCard.vue";
import FeedbackCard from "@/components/Home/FeedbackCard.vue";
import SeoHandler from "@/mixins/SeoHandler";
import queryString from "query-string";
import Other1 from "../components/HomeNew/Other1.vue";
import MoreFooterPartItem from "../layouts/MoreFooterPartItem.vue";
import eventEmitter from "../utils/eventEmitter";
import {nanoid} from 'nanoid'
import {urlImgHandle} from "../utils";

export default {
  name: 'IndexPage',
  mixins: [RudderStackHandler, CleverTapHandler, SeoHandler],
  components: {
    MoreFooterPartItem,
    Other1,
    FeedbackCard,
    ServiceCard,
    FeatureCard,
    DialogValuation,
    Banner,
    Feature,
    Brand,
    LazyHydrate,
    StatisticsList: () => import('@/components/Statistics/StatisticsHomeList'),
    TransitionGuestModal: () => import('@/components/TransitionGuestModal/TransitionGuestModal'),
    News: () => import('@/components/Home/News'),
    BoxHTML: () => import('@/components/Home/BoxHTML'),
    Feedback: () => import('@/components/Home/Feedback'),
    Partner: () => import('@/components/Home/Partner'),
    Service: () => import('@/components/HomeNew/Service'),
    Shop: () => import('@/components/Home/Shop'),
    FAQGeneral: () => import('@/components/FAQ/FAQGeneral'),
  },
  data() {
    return {
      // dataGeneral: [],
      banners: [],
      maxPercent: 0,
      percent: 0,
      workerCheck: null
    }
  },
  async asyncData({route, app}) {
    let loading = true
    let cars = []
    let favoriteCars = []
    let manufacturers = []
    let figures = []
    let news = []
    let partners = []
    let loadingPartner = true
    let dataGeneral = []
    let seoObj = {}

    const getFaq = async () => {
      try {
        const res = await app.$axios.$get(`${config.api.faq}?category=4`)
        if (res) {
          const {data, total} = res
          const arr = data?.data
          if ((arr || []).length > 5) {
            dataGeneral = arr.slice(0, 5)
          } else {
            dataGeneral = arr
          }
        }
      } catch (err) {
        console.log(err)
      }
    }

    const getPartners = async () => {
      try {
        loadingPartner = true
        const {data} = await app.$axios.$get(config.api.partner)
        partners = data
        loadingPartner = false
      } catch (err) {
        loadingPartner = false
      }
    }

    const getNews = async () => {
      try {
        const {data} = await app.$axios.$get(`${config.api.news}?${queryString.stringify({perPage: 4})}`)
        if (data && data.length) {
          news = data
        }
      } catch (err) {

      }
    }

    const getCarFeature = async () => {
      try {
        const {data} = await app.$axios.$get(`${config.api.manufacturer}?${queryString.stringify({perPage: 1000})}`)
        manufacturers = data
      } catch (err) {
        console.log(err)
      }

      try {
        const {data} = await app.$axios.$get(`${config.api.figure}?${queryString.stringify({perPage: 1000})}`)
        figures = data
      } catch (err) {
        console.log(err)
      }
    }

    const checkFavorited = (carId) => {
      return favoriteCars.includes(carId)
    }

    const getFavoriteCars = async () => {
      try {
        const {cars: carList} = await app.$axios.$get(`${config.api.favoriteCar}/all`)
        if (carList && carList.length) {
          favoriteCars = carList
        } else {
          favoriteCars = []
        }
      } catch (e) {
        favoriteCars = []
      }
    }

    const handleSeo = async () => {
      const getScreenType = (path) => {
        switch (path) {
          case '/':
            return config.screenType.HOME_PAGE
          case '/mua-xe':
            return config.screenType.CAR_PURCHASE
          case '/ban-xe':
            return config.screenType.SELL_CAR
          case '/gioi-thieu':
            return config.screenType.INTRODUCE
          case '/tin-tuc':
            return config.screenType.NEWS_HOME_PAGE
          case '/xe-yeu-thich':
            return config.screenType.FAVORITE_CAR
          case '/tuyen-dung':
            return config.screenType.RECRUIT
        }
      }

      const screenType = getScreenType(route.path)

      try {
        const {data: seoUrl} = await app.$axios.get(`${config.api.seoUrl}?url=${route.path}`)
        if (seoUrl && seoUrl._id) {
          seoObj = {
            seoUrl,
            pageTitle: seoUrl.title,
            description: seoUrl.description,
            image: urlImgHandle(seoUrl.image, {w: 1200})
          }
        } else {
          if (screenType) {
            try {
              const seo = await app.$axios.$get(`${config.api.seo}/${screenType}`)

              if (seo && seo._id) {
                const {attributes} = seo
                seoObj = {
                  seo,
                  pageTitle: attributes.title,
                  description: attributes.description,
                  image: urlImgHandle(attributes.imgCover, {w: 1200})
                }
              }
            } catch (err) {
              seoObj = {
                seo: {},
                pageTitle: 'CARPLA - Nền tảng mua bán xe ô tô đã qua sử dụng lớn nhất Việt Nam',
                description: '',
                image: ''
              }
            }
          }
        }
      } catch (err) {
        return {
          seo: {},
          pageTitle: 'CARPLA - Nền tảng mua bán xe ô tô đã qua sử dụng lớn nhất Việt Nam',
          description: '',
          image: ''
        }
      }
    }

    try {
      await Promise.allSettled([
        await handleSeo(),
        await getCarFeature(),
        await getFavoriteCars(),
        await getNews(),
        await getPartners(),
        await getFaq()
      ])
      const response = await app.$axios.$get(`${config.api.car}?limit=8&sort=desc`)
      const carResponse = response?.data
      cars = (carResponse || []).map(item => ({
        ...item,
        isFavorite: checkFavorited(item.id)
      }))
      loading = false
      return {
        cars,
        loading,
        favoriteCars,
        manufacturers,
        figures,
        news,
        partners,
        loadingPartner,
        dataGeneral,
        ...seoObj
      }
    } catch (err) {
      loading = false
      return {
        cars: [],
        loading: false,
        favoriteCars: [],
        manufacturers: [],
        figures: [],
        news: [],
        partners: [],
        loadingPartner: false,
        dataGeneral: [],
        ...seoObj
      }
    }
  },
  // head() {
  //   if (this.seo && this.seo._id) {
  //     return {
  //       title: `${this.pageTitle} | Carpla`,
  //       meta: [
  //         {hid: 'description', name: 'description', content: this.description},
  //         {hid: 'og-image', property: 'og:image', content: this.image},
  //         {hid: 'og-image', property: 'og:image:secure_url', content: this.image},
  //       ]
  //     }
  //   }
  // },
  // async asyncData({route, app}) {
  //   const urlImg = (path, imgOption = {}) => {
  //     const {w, h, q, fit, blur} = imgOption
  //     let cdn = `${config.api.cdn}`
  //
  //     if (fit) {
  //       cdn = `${config.api.cdn}/fit-in`
  //     }
  //
  //     if (w || h) {
  //       cdn += `/${w || ''}x${h || ''}`
  //     }
  //
  //     if (q) {
  //       cdn += `/filters:quality(${q})`
  //     }
  //
  //     if (blur) {
  //       if (q) {
  //         cdn += `:blur(${blur}`
  //       } else {
  //         cdn += `/filters:blur(${blur})`
  //       }
  //     }
  //
  //     if (!path) {
  //       return ''
  //     }
  //
  //     if (path && (path.includes('http') || path.includes('https'))) {
  //       return path
  //     }
  //
  //     if (path && path[0] === '/') {
  //       return `${cdn}/${path}`
  //     }
  //     return `${cdn}/${path}`
  //   }
  //
  //   try {
  //     const seo = await app.$axios.$get(`${config.api.seo}/${config.screenType.HOME_PAGE}`)
  //     if (seo && seo._id) {
  //       const {attributes} = seo
  //       return {
  //         seo,
  //         pageTitle: attributes.title,
  //         description: attributes.description,
  //         image: urlImg(attributes.imgCover, {w: 1200})
  //       }
  //     }
  //     return {}
  //   } catch (err) {
  //     return {
  //       seo: {},
  //       pageTitle: 'Carpla',
  //       description: '',
  //       image: ''
  //     }
  //   }
  // },
  created() {
    console.log('home_page_created')
  },
  async mounted() {
    this.rudderPage()
    this.clevertapTrack(config.eventTypeTracking.page_view, {page: window.location.href})
    this.$ga.page('/')
    console.log('version: dev-7bf2dd0f')
    if (process.client) {
      await this.startListeners();
      setTimeout(() => {
        try {
          const isShow = localStorage.getItem("isShowPopUp")
          if (!isShow) {
            clevertap.notifications.push({
              "titleText": 'Would you like to receive Push Notifications?',
              "bodyText": 'We promise to only send you relevant content and give you updates on your transactions',
              "okButtonText": 'Sign me up!',
              "rejectButtonText": 'No thanks',
              "serviceWorkerPath": "/sw.js",
              "okButtonColor": '#f28046'
            });
            localStorage.setItem("isShowPopUp", 1)
          }
        } catch (e) {
          console.error(e)
        }
      }, 10000)
    }

    const oldUserId = localStorage.getItem('sessionUserId');
    if (!oldUserId) {
      localStorage.setItem('sessionUserId', nanoid() + (new Date).getTime().toString());
    }
    const myEvent = window.attachEvent || window.addEventListener;
    const chkevent = window.attachEvent ? 'onbeforeunload' : 'beforeunload'; /// make IE7, IE8 compitable
    myEvent(chkevent, function (e) { // For >=IE7, Chrome, Firefox
      localStorage.removeItem('sessionUserId');
    });

    // gen session
    this.workerCheck = new Worker("/gen-session-sw.js")
    this.workerCheck.onmessage = (e) => {
      const {data} = e
      switch (data.event) {
        case 'changeSessionId': {
          localStorage.setItem('sessionUserId', (new Date).getTime().toString());
          break
        }
      }
    }
    eventEmitter.on('workerCheckEmit', () => {
      this.workerCheck?.postMessage("changeSessionUserId")
    })
  },
  methods: {
    async startListeners() {
      await this.requestPermission();
      await this.getIdToken();
    },
    async requestPermission() {
      try {
        await Notification.requestPermission();
      } catch (e) {
        // console.error("Error : ", e);
      }
    },
    async getIdToken() {
      try {
        const idToken = await this.$fire.messaging.getToken();
        if (idToken) {
          const data = {fcmToken: idToken}
          await this.$store.dispatch(ACTION_SET_TOKEN_NOTIFY, data)
        }
      } catch (e) {
        // console.error("Error : ", e);
      }
    },
  }
}
// setTimeout(()=> {
//   const frames = document.getElementsByTagName('iframe');
//   for (let i in frames)
//     console.log('iframs', i)
// }, 3000)

// if (!frames[i].src.match(/^http:\/\/www\.website\.com/))
//   document.body.removeChild(frames[i]);
