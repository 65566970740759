
import SlickCarousel from "@/components/Elements/SlickCarousel.vue";
import {ACTION_GET_RATES} from "@/utils/store.action";
import config from "@/utils/config";
import urlImageHandler from "@/mixins/UrlImageHandler";
import NuxtSSRScreenSize from "nuxt-ssr-screen-size";

export default {
  props: ['screen'],
  name: 'FeedbackCard',
  mixins: [urlImageHandler, NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  components: {SlickCarousel},
  data() {
    return {
      feedbacks: [],
      slickOptions: {
        slidesPerRow: 1,
        rows: 2,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
        dots: true,
        infinite: true,
        dotsClass: 'custom-carousel-dots',
        speed: 500
      },
    }
  },
  watch: {
    $vssWidth: {
      handler: function (breakPoint) {
        this.responsiveCarousel(breakPoint)
      },
      deep: true,
      immediate: true
    },
  },
  async created() {
    let res
    if (this.screen) {
      res = await this.$store.dispatch(ACTION_GET_RATES, {screen: this.screen})
    } else {
      res = await this.$store.dispatch(ACTION_GET_RATES, {screen: config.screenFeedback.HOME_PAGE})
    }
    if (res?.data) {
      this.feedbacks = [...res.data]
      this.responsiveCarousel(this.$vssWidth)

      if (this.feedbacks && this.feedbacks.length < 4) {
        this.slickOptions.rows = 1
      } else {
        this.slickOptions.rows = 2
      }
    }
  },
  methods: {
    responsiveCarousel(breakPoint) {
      if (breakPoint <= 640) {
        this.slickOptions.rows = 1
        this.slickOptions.slidesToShow = 1
        this.slickOptions.slidesToScroll = 1
        this.slickOptions.arrows = false
      } else if (breakPoint < 920) {
        this.slickOptions.rows = 1
        this.slickOptions.slidesToShow = 2
        this.slickOptions.slidesToScroll = 2
        this.slickOptions.arrows = false
      } else {
        this.slickOptions.rows = 2
      }
    }
  }
}
