
import UnderlineBar from "@/components/Elements/Underline.vue";
import img1 from '../../assets/imgs/home/img_1.png'
import img from '../../assets/imgs/home/img.png'
export default {
  name: 'ServiceCard',
  components: {UnderlineBar},
  data() {
    return {
      services: [
        {title: 'Bán xe', img: img, route: '/ban-xe'},
        {title: 'Mua xe', img: img1, route: '/mua-xe'},
        // {title: 'Định giá xe', img: "/home/img_2.png", modal: true},
        {title: 'Bảo hiểm', img: img, route: 'https://baohiemtasco.vn', target: '_blank'},
      ]
    }
  },
  methods: {
    onClick(item) {
      if(item.route) {
        if(item.target) {
          window.location.href = item.route
        } else {
          this.$router.push(item.route)
        }
      } else if(item.modal) {
        this.$nuxt.$emit('toggleValuationCar')
      }
    }
  }
}
