
import ResizeHandler from "@/mixins/ResizeHandler";
import UrlImageHandler from "@/mixins/UrlImageHandler";

export default {
  name: "BannerComponent",
  mixins: [UrlImageHandler, ResizeHandler],
  props: {
    banners: Array,
    hrefProps: String,
    showButton: {
      type: Boolean,
      default: false
    },
    haveModal: {
      type: Boolean,
      default: false
    },
    methodModal: Function
  },
  data() {
    return {
      imgUrl: '',
      timeout:null
    }
  },
  watch: {
    windowWidth: {
      handler: function (breakpoint) {
        setTimeout(() => {
          if (breakpoint < 768) {
            this.imgUrl = this.banners[0]?.imageMobile
          } else {
            this.imgUrl = this.banners[0]?.image_url
          }
        }, 200)
      },
      deep: true,
      immediate: true
    }
  },
  beforeRouteLeave(){
    clearTimeout(this.interval)
  },
  methods: {
    redirect(item) {
      if (item.link_url) {
        window.location.href = item.link_url
      }
    }
  }
}
