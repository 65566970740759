

import config from '@/utils/config'
import UnderlineBar from '@/components/Elements/Underline'
import UrlImageHandler from '@/mixins/UrlImageHandler'
import RudderStackHandler from '@/mixins/RudderStackHandler'
import CleverTapHandler from "@/mixins/CleverTapHandler";

export default {
  name: 'Brand',
  mixins: [UrlImageHandler, RudderStackHandler, CleverTapHandler],
  components: {UnderlineBar},
  props: ['manufacturers', 'figures'],
  data() {
    return {
      config,
      brandList: [],
      styleList: []
    }
  },
  watch: {
    manufacturers: {
      handler: function (data) {
        this.brandList = data.slice(0, 7)
      },
      deep: true,
      immediate: true
    },
    figures: {
      handler: function (data) {
        this.styleList = data.slice(0, 7)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onClickManufacturer() {
    },
    onClickFigure(item) {
//clevertap
      this.clevertapTrack(config.eventTypeTracking.homepage_viewed, {
        style_car_clicked: item._id,
        button_clicked: this.item.name,
        screen_name: 'Trang chủ',
        page_name: 'man_hinh_trang_chu'
      })
    }
  }
}
